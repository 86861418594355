import { Modify } from "@biblioteksentralen/types";
import { Event } from "@libry-content/types";
import { repeatedEventNotFinished } from "../groq/filters";
import groq from "groq";

export const groqEventStart = groq`select(allDay => '00:00', eventStart)`;

export const groqStartDate = groq`select(repeated => repeatedDates[${repeatedEventNotFinished}][0].date, startDate)`;

// By performing the flattening in the query, we can use groq's ordering and pagination.
// Note that as is this can not be used where the events are to be filtered, as in the website frontend.
export const flattenRepeatedEvents = (repeatedDateFilter = "") => groq`
  {"occurrences": select(
    repeated => repeatedDates[${repeatedDateFilter}] {
      ...^, // Spread the parent event
      ...customization, // Spread the single date customization
      "startDate": date, // Single date
      "repeatedDates": null, // Erase repeated dates array to avoid confusion
      "series": {"id": ^._id}, // Series id from the parent event
      "_id": _key, // Single date key as id
      "_type": ^._type // Use event type instead of that spread from customization TODO, maybe use unique type, for example "resolvedEventOccurence" to avoid confusion?
    },
    [@] // Non-repeated event
  )}
  .occurrences[] // Flatten
 `;

export type FlattenedEvent = Modify<
  Event,
  {
    series: { id: string };
  }
>;

export const isFlattenedEvent = <T extends FlattenedEvent>(doc: unknown): doc is T =>
  (doc as FlattenedEvent)?._type === "event" && !!(doc as FlattenedEvent)?.series;
