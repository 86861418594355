import groq from "groq";
import { campaignIsInSeasonGroqFilter, customCampaignIsOngoingGroqFilter, templateCampaignTypes } from "../campaigns";
import { digitalLibraryFilter } from "../digitalLibrary";
import { flattenRepeatedEvents, groqEventStart } from "../events/groq";
import { repeatedEventNotFinished } from "../groq/filters";

export const getPromotionFilter = (promotionPath = "") =>
  groq`(${promotionPath}promotion.startsAt <= now() && ${promotionPath}promotion.endsAt > now())`;

const orderByPromotionEndsAt = groq`order(promotion.endsAt desc)`;
const promoteRepeatedEventOccurenceFilter = groq`${repeatedEventNotFinished} && ${getPromotionFilter(
  "@.customization."
)}`;
// Order lists and recommendations by promotion.endsAt if promoted to allow editors to lift old lists and recommendations to the top
export const orderListsAndRecommendations = groq`order(coalesce(promotion.endsAt, _createdAt) desc)`;

const promotionFilter = getPromotionFilter();
const eventsQuery = (resolver: string) => groq`*[
    _type == "event" && select(
      repeated => count(repeatedDates[${promoteRepeatedEventOccurenceFilter}]) > 0, 
      ${promotionFilter}
    )
  ]
  ${flattenRepeatedEvents(promoteRepeatedEventOccurenceFilter)}
  | order(startDate asc, ${groqEventStart} asc)
  {${resolver}}`;

const servicesQuery = (resolver: string) =>
  groq`*[_type == "service" && ${promotionFilter}] | ${orderByPromotionEndsAt} {${resolver}}`;

const digitalLibraryServicesQuery = (resolver: string) =>
  groq`*[${digitalLibraryFilter} && ${promotionFilter}] | ${orderByPromotionEndsAt} {${resolver}}`;

const listsAndRecommendationsQuery = (
  resolvers: PromotedItemsResolvers
) => groq`*[_type in ["list", "recommendation"] && ${promotionFilter}] | ${orderListsAndRecommendations} {
  _type == "list" => {${resolvers.list}},
  _type == "recommendation" => {${resolvers.recommendation}}
}`;

const templateCampaignsQuery = (resolvers: PromotedItemsResolvers) => groq`*[_type in ${JSON.stringify(
  templateCampaignTypes
)} && (${campaignIsInSeasonGroqFilter})] | order(startDate) {
  _type == "sommerles" => {${resolvers.sommerles}},
  _type == "adventCalendar" => {${resolvers.adventCalendar}},
  _type == "bibliobattles" => {${resolvers.bibliobattles}},
}`;

const customCampaignsQuery = (resolver: string) =>
  groq`*[_type == "customCampaign" && ${customCampaignIsOngoingGroqFilter}] | order(startDate) {${resolver}}`;

export const getPromotedItemsQuery = (maxNoItems: number, resolvers: PromotedItemsResolvers) => groq`
  [
    ...${templateCampaignsQuery(resolvers)},
    ...${customCampaignsQuery(resolvers.customCampaign)},
    ...${eventsQuery(resolvers.event)},
    ...${servicesQuery(resolvers.service)},
    ...${digitalLibraryServicesQuery(resolvers.digitalLibraryService)},
    ...${listsAndRecommendationsQuery(resolvers)},
  ]
  [defined(@)] // Filter out null-entries
  [0...${maxNoItems}]
`;

export type PromotedItemsResolvers = Record<
  | "event"
  | "service"
  | "digitalLibraryService"
  | "list"
  | "recommendation"
  | "sommerles"
  | "adventCalendar"
  | "bibliobattles"
  | "customCampaign",
  string
>;
