import { getIsInsideAnIframe } from "@libry-content/common";
import { useSearchParams } from "next/navigation";
import { createContext, lazy, PropsWithChildren, Suspense, useContext, useEffect, useState } from "react";

const SanityLiveQueryProvider = lazy(() => import("./SanityLiveQueryProvider"));

type State = {
  isOn: boolean;
};

const initState: State = {
  isOn: false,
};

const Context = createContext<State>(initState);

export const usePreviewContext = () => useContext(Context);

export const PreviewContextProvider = (props: PropsWithChildren) => (
  <Suspense fallback={props.children}>
    <SuspendedPreviewContextProvider>{props.children}</SuspendedPreviewContextProvider>
  </Suspense>
);

export const SuspendedPreviewContextProvider = (props: PropsWithChildren) => {
  const searchParams = useSearchParams();
  const previewModeInUrl = !!searchParams?.get("preview");
  // Need to persist in state because queryparams are not perserved when navigating to a new page in the preview
  const [previewModePersisted, setPreviewModePersisted] = usePersistantState("libryContentPreviewMode", initState.isOn);

  // State in url is available before state is set, so we use it if it exists to avoid flickering
  const previewMode = previewModeInUrl || previewModePersisted;

  useEffect(() => {
    if (previewModeInUrl) {
      setPreviewModePersisted(true);
    }
  }, [previewModeInUrl, setPreviewModePersisted]);

  useEffect(() => {
    previewMode === true && console.log("🔎 preview on"); // Å logge at vi er i preview-mode har vist seg å være nyttig mtp debugging, feks hvis man er i preview-mode uten å være klar over det selv
  }, [previewMode]);

  const contextProviderNode = (
    <Context.Provider
      value={{
        isOn: previewMode,
      }}
    >
      {props.children}
    </Context.Provider>
  );

  if (!previewMode) return contextProviderNode;

  return <SanityLiveQueryProvider>{contextProviderNode}</SanityLiveQueryProvider>;
};

// We need to store state in session storage to share between app router and pages router (They don't mount the same instance of provdiers)
const usePersistantState = <State extends boolean>(key: string, initialValue: State) => {
  const [state, setState] = useState(() => {
    if (typeof window === "undefined") return initialValue;
    const isInsideIframe = getIsInsideAnIframe();
    if (!isInsideIframe) return initialValue; // Don't persist state if we're not inside an iframe, for example when the user navigates from studio to the front page.
    const value = window.sessionStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  });

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState] as const;
};
