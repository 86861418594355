import groq from "groq";

const notRepeated = groq`(!repeated || !defined(repeated))`;
const notAllDay = groq`(!allDay || !defined(allDay))`;

export const eventOnDateOrLater = (dateVariable: string) => groq`(
  (allDay && ${notRepeated} && endDate >= ${dateVariable}) ||
  (${notAllDay} && ${notRepeated} && startDate >= ${dateVariable}) ||
  (repeated && count(repeatedDates[@.date >= ${dateVariable}]) > 0)
)`;

export const eventBeforeDate = (dateVariable: string) => groq`(
  (allDay && ${notRepeated} && endDate < ${dateVariable}) ||
  (${notAllDay} && ${notRepeated} && startDate < ${dateVariable}) ||
  (repeated && count(repeatedDates[@.date < ${dateVariable}]) > 0)
)`;

export const repeatedEventNotFinished = groq`(@.date > $today || @.date == $today && (coalesce(@.customization.eventEnd, ^.eventEnd) > $timeNow))`;

export const filters = Object.freeze({
  isDraft: groq`_id in path('drafts.**')`,
  isNotDraft: groq`!(_id in path('drafts.**'))`,
  siteRef: groq`(site._ref == $siteId || !defined(site))`,
  eventTodayOrLater: eventOnDateOrLater("$today"),
  eventNotFinished: groq`(
    ${notRepeated} && (
      (allDay && endDate >= $today) ||
      (${notAllDay} && (startDate > $today || startDate == $today && eventEnd > $timeNow))
    ) ||
    (repeated &&
      count(repeatedDates[${repeatedEventNotFinished}]) > 0)
  )`,
  eventBeforeToday: eventBeforeDate("$today"),
  eventFinished: groq`(
    (allDay && ${notRepeated} && endDate < $today) ||
    (${notAllDay} && ${notRepeated} && startDate < $today) ||
    (repeated && count(repeatedDates[@.date >= $today]) == 0)
  )`,
  eventLacksDates: groq`(
    (allDay && ${notRepeated} && !defined(endDate)) ||
    (${notAllDay} && ${notRepeated} && !defined(startDate)) ||
    (repeated && count(repeatedDates) == 0)
  )`,
});
