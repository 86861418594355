import { DEFAULT_LANGUAGE_CODE, EditorLocale, editorLocales } from "@libry-content/localization";
import { Site } from "@libry-content/types";
import { getSubdomainUrl, platformUrl } from "./platform";
import { z } from "zod";

export interface SpecialSite {
  _id: string;
  subdomain?: string;
  customDomain?: string;
  name: Record<EditorLocale, string>;
  languages: readonly EditorLocale[];
  defaultLanguage: EditorLocale;
}

export type SiteOrSpecialSite = Pick<Site, keyof SpecialSite> | SpecialSite;

// Språkene her styrer hvilke språk som dukker opp i språkvelger/er påkrevd og som kan brukes for å opprette nye nettsteder
// Språk kan styres med env-variabelen SANITY_STUDIO_ADMIN_SITE_LOCALES, bruk komma for flere språk (f.eks. "nb,nn")
const validEditorLanguagesSchema = z.preprocess((val) => String(val).split(","), z.array(z.enum(editorLocales)));
const getAdminSiteLanguages = (): EditorLocale[] => {
  if (!process.env.SANITY_STUDIO_ADMIN_SITE_LOCALES) {
    console.warn("SANITY_STUDIO_ADMIN_SITE_LOCALES not set, defaulting to nb,nn");
    return ["nb", "nn"];
  }

  const parsedLocalesFromEnv = validEditorLanguagesSchema.safeParse(process.env.SANITY_STUDIO_ADMIN_SITE_LOCALES);

  if (!parsedLocalesFromEnv.success)
    throw new Error(`Invalid SANITY_STUDIO_ADMIN_SITE_LOCALES: ${parsedLocalesFromEnv.error.message}`);

  return parsedLocalesFromEnv.data;
};

const adminSiteLanguages = getAdminSiteLanguages();

export const ADMIN_SITE: SpecialSite = {
  _id: "sites.admin",
  name: {
    nb: "Adminsiden",
    nn: "Adminsiden",
    de: "Adminsiden",
  },
  subdomain: "admin",
  languages: adminSiteLanguages,
  defaultLanguage: adminSiteLanguages[0] ?? DEFAULT_LANGUAGE_CODE,
};

export const SPLASH_SITE = {
  _id: "sites.splash",
  name: {
    nb: "Splashsiden",
    nn: "Splashsiden",
    de: "Splashsiden",
  },
  customDomain: platformUrl.host,
  languages: ["nb"],
  defaultLanguage: DEFAULT_LANGUAGE_CODE,
} satisfies SpecialSite;

const specialSiteIds = [ADMIN_SITE._id, SPLASH_SITE._id];

export const isNormalSite = (site: SiteOrSpecialSite | undefined): site is Site =>
  site?._id !== undefined && !specialSiteIds.includes(site._id);

const addDefaulLanguage = (site: SiteOrSpecialSite, skipLanguage: boolean, url: string): string =>
  skipLanguage || !site.defaultLanguage ? url : `${url}/${site.defaultLanguage}`;

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (site: SiteOrSpecialSite, { skipLanguage } = { skipLanguage: false }): string =>
  addDefaulLanguage(
    site,
    skipLanguage,
    site.customDomain ? `https://${site.customDomain}` : getSubdomainUrl(site.subdomain)
  );

export const splashSiteUrl = getSiteUrl(SPLASH_SITE, { skipLanguage: true });
