"use client";
/**
 * In this file we have to be careful with imports not to break the build (note that the
 * build might work in dev even if the production one doesn't). In particular we can't
 * use next-translate (https://github.com/i18next/next-i18next/issues/677),
 * therefore we use a simple substitute instead.
 */

/* eslint i18next/no-literal-string: 0  */
import { ArrowLeftIcon } from "@biblioteksentralen/icons";
import { Box, Button, colors, Flex, Grid, Heading, Spinner, Text, VStack } from "@biblioteksentralen/react";
import { filters } from "@libry-content/common";
import { FrontendLocale, validateFrontendLocaleOrFallbackToDefault } from "@libry-content/localization";
import { useEffect, useState } from "react";
import { DefaultContainer } from "../components/ContentContainer";
import { ErrorPageFigure } from "../components/errorPages/ErrorPageFigure";
import { ErrorPageHead } from "../components/errorPages/ErrorPageHead";
import { ErrorPageSiteHeader } from "../components/errorPages/ErrorPageSiteHeader";
import { errorPageTranslations } from "../components/errorPages/errorPageTranslations";
import { UnknownDomainHeader } from "../components/errorPages/UnknownDomainHeader";
import { LayoutGrid } from "../components/layout/LayoutGrid";
import { LocalizedNextLink } from "../components/LocalizedNextLink";
import SplashPageFooter from "../components/splashPage/SplashPageFooter";
import { ResolvedSite } from "../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { sanityClient } from "../utils/sanity/client";

type Props = {
  isInAppfolder?: boolean; // Kan fjernes når vi er helt over på app-folder
};

const ErrorPage = (props: Props) => {
  const hostname = typeof window !== "undefined" ? window.location.hostname : undefined;
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState<ResolvedSite | undefined>(undefined);
  const languageCode = validateFrontendLocaleOrFallbackToDefault(site?.defaultLanguage);

  // Kan fjernes når alt er migrert til app-folder
  useEffect(() => {
    if (typeof hostname === "undefined") return;

    sanityClient
      .fetch(`*[_type == "site" && domain == $siteDomain && ${filters.isNotDraft}][0]`, { siteDomain: hostname })
      .then((siteResponse) => setSite(siteResponse))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [hostname]);

  // I app-folder får vi header og footer fra layout
  if (props.isInAppfolder) return <Fillern locale={languageCode} site={site} />;

  if (loading) {
    return (
      <Grid placeContent="center" minHeight="100vh">
        <ErrorPageHead />
        <Spinner speed="1s" />
      </Grid>
    );
  }

  return (
    <Box>
      <ErrorPageHead />
      <LayoutGrid>
        {site?.name ? (
          <ErrorPageSiteHeader siteName={site.name} languageCode={languageCode} />
        ) : (
          <UnknownDomainHeader />
        )}
        <Fillern locale={languageCode} site={site} />
        <SplashPageFooter
          backgroundColor={colors.grey90}
          color={colors.white}
          fontSize="sm"
          innerContainerMaxWidth="container.xl"
        />
      </LayoutGrid>
    </Box>
  );
};

const Fillern = (props: { locale: FrontendLocale; site?: ResolvedSite }) => (
  <DefaultContainer padding={{ base: "3rem 0 5rem", md: "5rem 0 7rem" }}>
    <Grid gridTemplateColumns={{ base: "1fr", sm: "1fr 1fr" }}>
      <Flex
        justifyContent={{ base: "center", md: "flex-end" }}
        alignItems="center"
        marginRight={{ base: undefined, md: "2rem" }}
        marginBottom={{ base: "2rem", sm: "0" }}
      >
        <Box width={{ base: "12rem", md: "16rem" }} height={{ base: "12rem", md: "16rem" }}>
          <ErrorPageFigure />
        </Box>
      </Flex>
      <VStack as="main" id="main" gap="1rem" alignItems="flex-start" padding={{ base: "0 1rem 0", sm: "0 3rem" }}>
        <Heading as="h1" size="3xl" marginTop="0 !important">
          {errorPageTranslations[props.locale]["Filler'n!"]}
        </Heading>
        <Heading as="h2" fontSize="2xl" marginTop="0 !important">
          {errorPageTranslations[props.locale]["Dette var uventet."]}
        </Heading>
        <Text marginTop="0 !important" fontSize="sm" maxWidth="17rem">
          {
            errorPageTranslations[props.locale][
              "Noe har gått galt. Ha litt tålmodighet med oss, så løser det seg nok snart."
            ]
          }
        </Text>
        <Text marginTop="0 !important" fontSize="sm">
          {"("}
          {errorPageTranslations[props.locale]["Feilkode 500"]}
          {")"}
        </Text>
        {!!props.site && (
          <Button
            as={LocalizedNextLink}
            href="/"
            size="md"
            leftIcon={<ArrowLeftIcon />}
            marginTop={{ md: "1rem !important" }}
          >
            {errorPageTranslations[props.locale]["Gå til forsiden"]}
          </Button>
        )}
      </VStack>
    </Grid>
  </DefaultContainer>
);

export default ErrorPage;
