import { Blocks } from "@libry-content/types";
import de from "date-fns/locale/de";
// eslint-disable-next-line no-restricted-imports -- We avoid importing these directly except for this file
import nb from "date-fns/locale/nb";
import nn from "date-fns/locale/nn";

export const editorLocales = ["nb", "nn", "de"] as const;
export const frontendLocales = ["nb", "nn"] as const; // We only need german in the editor atm, so translating the entire frontend seems unessecarry for now

export type EditorLocale = (typeof editorLocales)[number];
export type FrontendLocale = (typeof frontendLocales)[number];

export const isValidFrontendLocale = (lang: any): lang is FrontendLocale => frontendLocales.includes(lang);
export const isValidEditorLocale = (lang: any): lang is EditorLocale => editorLocales.includes(lang);

export const validateFrontendLocaleOrFallbackToDefault = (value?: any): FrontendLocale =>
  isValidFrontendLocale(value) ? value : "nb";

export type FieldValue = boolean | string | Blocks | undefined;

export type LocalizedField<T = FieldValue> = Partial<Record<EditorLocale, T>>;

const omit = (obj: object, omittedKey: string): object =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => omittedKey !== key));

export const isLocalizedField = (value: unknown): value is object =>
  typeof value === "object" && value != null && Object.keys(omit(value, "_type")).every(isValidFrontendLocale);

export const languageLabels = {
  nb: "Bokmål",
  nn: "Nynorsk",
  de: "Deutsch",
} as const satisfies Record<EditorLocale, string>;

export const DEFAULT_LANGUAGE_CODE = "nb" satisfies EditorLocale;

export const translateSanity = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  locale: EditorLocale
): T | undefined => {
  if (typeof localizedObject === "string") {
    console.error("Attempted to get localized text from a string:", localizedObject);
    return localizedObject;
  }

  return localizedObject?.[getLocaleForSanityContent(localizedObject, locale)];
};

export const getLocaleForSanityContent = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  prefferedLang: EditorLocale
): EditorLocale => {
  if (!localizedObject) return prefferedLang;
  if (prefferedLang in localizedObject) return prefferedLang;

  // Random fallback
  return editorLocales.find((lang) => lang in localizedObject) ?? prefferedLang;
};

export const dateFnsLocaleMap: Record<EditorLocale, Locale> = { nb, nn, de };

/**
 * Example: localizedPortableTextGroq("body") -> "nb": pt::text(body.nb), "nn": pt::text(body.nn)
 */
export const localizedPortableTextGroq = (fieldName: string) =>
  editorLocales.map((code) => `"${code}": pt::text(${fieldName}.${code})`).join(", ");

const hasCanonicalLocalesFunction = (item: unknown): item is { getCanonicalLocales: (code: string) => string[] } =>
  typeof item === "object" && typeof (item as any)?.["getCanonicalLocales"] === "function";

const getIntlCanonicalLanguageCode = (languageCode: string): string | undefined => {
  if (!hasCanonicalLocalesFunction(Intl)) return undefined;

  // @ts-ignore -- not yet included in ts but mostly supported: https://github.com/microsoft/TypeScript/issues/29129
  const canonicalLanguageCodes = Intl.getCanonicalLocales(languageCode);
  return canonicalLanguageCodes?.[0];
};

export const getCanonicalLanguageCode = (languageCode: string | undefined): string | undefined => {
  if (!languageCode) return undefined;

  try {
    const canonicalLanguageCode = getIntlCanonicalLanguageCode(languageCode);
    if (canonicalLanguageCode) return canonicalLanguageCode;
  } catch {
    // No action
  }

  try {
    const locale = new Intl.Locale(languageCode);
    return locale.baseName;
  } catch {
    console.warn(`Attempted to get the canonical language code for ${languageCode}`);
    return undefined;
  }
};

/**
 * The standard language for catalogization is Norwegian bokmål, which means that some
 * publication fiels, e.g. description, are always given in this language.
 */
export const catalogueLanguageCode: EditorLocale = "nb";
